import { Box } from "@twilio-paste/core/box";
import { Text } from "@twilio-paste/core/text";

import Logo from "../assets/manning_logo.png";
import { containerStyles, titleStyles } from "./styles/Header.styles";
import { Avatar, AvatarContainer } from "./styles/Avatar.styles";

export const Header = ({ customTitle }: { customTitle?: string }) => {
    return (
        <Box as="header" {...containerStyles}>
            <AvatarContainer>
                <Avatar onChat={false} src={Logo} />
            </AvatarContainer>
            <Text as="h2" {...titleStyles}>
                {customTitle || "NY Mannings Web Chat"}
            </Text>
        </Box>
    );
};
