import styled from "@emotion/styled";

interface AvatarProps {
    onChat?: boolean;
}

export const Avatar = styled.img<AvatarProps>`
    width: ${(props) => (props.onChat ? "16px" : "24px")};
    height: ${(props) => (props.onChat ? "16px" : "24px")};
`;

export const AvatarContainer = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #fff;
`;
