import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const containerStyles: BoxStyleProps = {
    display: "flex",
    alignItems: "center",
    height: "68px",
    fontWeight: "fontWeightBold",
    fontSize: "fontSize10",
    backgroundColor: "colorBackgroundPrimaryStronger",
    padding: "space20",
    paddingTop: "space40",
    paddingBottom: "space40",
    borderTopLeftRadius: "borderRadius30",
    borderTopRightRadius: "borderRadius30"
};

export const titleStyles: TextStyleProps = {
    color: "colorTextWeakest",
    paddingLeft: "space30"
};
