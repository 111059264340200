import { Box } from "@twilio-paste/core/box";
import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { changeExpandedStatus } from "../store/actions/genericActions";
import { containerStyles } from "./styles/EntryPoint.styles";

export const EntryPoint = () => {
    const dispatch = useDispatch();
    const savedState = typeof window !== "undefined" && window.localStorage ? localStorage.getItem("expanded") : null;
    const initialState = savedState ? JSON.parse(savedState) : false;
    const [expanded, setExpanded] = useState(initialState);

    useEffect(() => {
        dispatch(changeExpandedStatus({ expanded }));

        if (typeof window !== "undefined" && window.localStorage) {
            localStorage.setItem("expanded", JSON.stringify(expanded));
        }
    }, [expanded, dispatch]);

    return (
        <Box as="button" data-test="entry-point-button" onClick={() => setExpanded(!expanded)} {...containerStyles}>
            {expanded ? (
                <ChevronDownIcon decorative={false} title="Minimize chat" size="sizeIcon80" />
            ) : (
                <ChatIcon decorative={false} title="Open chat" size="sizeIcon60" />
            )}
        </Box>
    );
};
