import styled from "@emotion/styled";

interface ButtonProps {
    isDisabled: boolean;
}

export const ButtonDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Button = styled.button<ButtonProps>`
    min-width: 85px;
    height: 45px;
    color: ${(props) => (props.disabled ? "#6d6d6d" : "#000")};
    border-radius: 3px;
    background-color: ${(props) => (props.disabled ? "#d2d2d2" : "#FFF")};
    border: 1px solid ${(props) => (props.disabled ? "#b8b8b8" : "#000")};
    margin: 5px 0;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
